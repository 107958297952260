import React from "react"
import "../assets/styles/global.css"

import FullPageLayout from "../layouts/FullPageLayout"
import Section from "../components/Section/Section"
import Testimonials from "../components/Content/Testimonials/Testimonials"
import Sponsors from "../components/Content/Testimonials/Sponsors"
import Footer from "../components/Bar/footer"
import { REFERENCES_TOOLTIPS } from "../const/MenuTooltips"
import { SEO_OPTIONS_REFERENCES } from "../const/SEOOptions"

const ReferencesPage = ({location}) => {
  const fullpageOptions = {
    navigationTooltips: REFERENCES_TOOLTIPS
  }

  return (
    <FullPageLayout
      options={fullpageOptions}
      SEOOptions={SEO_OPTIONS_REFERENCES}

      backgroundImage={false}
      location={location}
    >
      <Section isFirstSection
               IsResponsiveGradient={false}
               Background ={"rgba(1, 9, 33, 1)"}
               GradientBackground={`radial-gradient( circle at 50% 98%, rgba(4, 19, 72, 1) 0%, rgba(1, 9, 33, 1) 18rem )`}
        autoHeight
        SectionMinHeight={"75vh"}
        Padding={"25vh 0 0 0"}
      >
        <Testimonials />
      </Section>

      <Section
        IsResponsiveGradient={false}
        Background ={"rgba(1, 9, 33, 1)"}
        GradientBackground={`radial-gradient( circle at 50% 2%, rgba(4, 19, 72, 1) 0%, rgba(1, 9, 33, 1) 18rem )`}
        autoHeight
      >
        <Sponsors />
        <Footer location={location} />
      </Section>


    </FullPageLayout>
  )
}

export default ReferencesPage
